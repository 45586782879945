import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Breadcrumb, DatePicker, Select, Row, Col} from 'antd';
import {Link, navigate} from 'gatsby';
import {connect} from 'react-redux';
import moment from 'moment';
import ChevronRight from '~components/icons/chevron-right';
import {register} from '~actions/user';
import {get} from '~utils/api';
import Loading from '~components/page-loader';

const Register = ({registerConsumer, user}) => {
  const [cities, setCities] = useState([]);
  const [district, setDistrict] = useState([]);
  const [ward, setWard] = useState([]);
  const [loading, setLoading] = useState(false);

  const onFinish = async values => {
    if (values) {
      try {
        setLoading(true);
        const {username, password, fullname, email, mobileno, birthDate, street, wardId, districtId, cityId} = values;
        const request = {
          username,
          password,
          fullname,
          email,
          mobileno,
          birthDate: birthDate ? moment(birthDate).format('YYYY-MM-DDTHH:mm:ssZ') : undefined,
          address: {
            street,
            wardId,
            districtId,
            cityId,
          },
        };
        const res = await registerConsumer(request);
        if (!res) return;
        navigate('/login');
      } finally {
        setLoading(false);
      }
    }
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const sort = array => {
    return array.sort((a, b) => (a.label > b.label ? 1 : -1));
  };

  const handleSelectCity = async value => {
    try {
      setLoading(true);
      const res = await get(`/v1/address/${value}/district`);
      if (!res) return;
      setDistrict(sort(res.items?.map(x => ({label: x.name, value: x.id})) || []));
    } finally {
      setLoading(false);
    }
  };

  const handleSelectDistrict = async value => {
    try {
      setLoading(true);
      const res = await get(`/v1/address/${value}/ward`);
      if (!res) return;
      setWard(sort(res.items?.map(x => ({label: x.name, value: x.id})) || []));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && user.token) {
      navigate('/home');
      return;
    }
    const getCitiInfo = async () => {
      try {
        setLoading(true);
        const res = await get('/v1/address/city');
        if (!res) return;
        setCities(sort(res.items?.map(x => ({label: x.name, value: x.id})) || []));
      } finally {
        setLoading(false);
      }
    };

    getCitiInfo();
  }, []);

  return (
    <div className='register'>
      <Loading loading={loading} />
      <Row justify='center' className='breadcrumb-header com-bg-gray'>
        <Col xs={24} md={23} lg={21}>
          <Breadcrumb separator={<ChevronRight fill='#D8D6D6' />}>
            <Breadcrumb.Item>
              <Link to='/home' className='pro-title-name'>
                Trang chủ
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <div className='pro-title-name'>Đăng ký</div>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row justify='center' className='content'>
        <Col xs={24} md={23} lg={21}>
          <div className='reg-title'>Đăng Ký Thành Viên</div>
          <Form
            name='registerform'
            className='register-form'
            layout='vertical'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label='Họ và tên'
              name='fullname'
              rules={[{required: true, message: 'Vui lòng nhập họ và tên!'}]}
            >
              <Input className='form-item' placeholder='Họ và tên' />
            </Form.Item>
            <Form.Item label='Ngày sinh' name='birthDate'>
              <DatePicker className='form-item' placeholder='Ngày sinh' format='DD/MM/YYYY' />
            </Form.Item>
            <Form.Item
              label='Tên đăng nhập'
              name='username'
              rules={[{required: true, message: 'Vui lòng nhập tên đăng nhập!'}]}
            >
              <Input className='form-item' placeholder='Tên đăng nhập' />
            </Form.Item>
            <Form.Item label='Mật khẩu' name='password' rules={[{required: true, message: 'Vui lòng nhập mật khẩu!'}]}>
              <Input.Password className='form-item' placeholder='Mật khẩu' />
            </Form.Item>
            <Form.Item
              label='Số điện thoại'
              name='mobileno'
              rules={[{required: true, message: 'Vui lòng nhập số điện thoại!'}]}
            >
              <Input className='form-item' placeholder='Số điện thoại' />
            </Form.Item>
            <Form.Item label='Email' name='email' rules={[{required: true, message: 'Vui lòng nhập email!'}]}>
              <Input className='form-item' placeholder='Email' />
            </Form.Item>
            <Form.Item label='Địa chỉ' name='street'>
              <Input className='form-item' placeholder='Số nhà, đường' />
            </Form.Item>
            <Form.Item label='Tỉnh/Thành phố' name='cityId'>
              <Select
                size='large'
                showSearch
                options={cities}
                loading={cities.length === 0}
                placeholder='Tỉnh/Thành phố'
                onChange={handleSelectCity}
                filterOption
                optionFilterProp='label'
              />
            </Form.Item>
            <Form.Item label='Quận/Huyện' name='districtId'>
              <Select
                size='large'
                showSearch
                options={district}
                loading={district.length === 0}
                placeholder='Quận/Huyện'
                onChange={handleSelectDistrict}
                filterOption
                optionFilterProp='label'
              />
            </Form.Item>
            <Form.Item label='Phường/Xã' name='wardId'>
              <Select
                size='large'
                showSearch
                options={ward}
                loading={!Array.isArray(ward) || ward.length === 0}
                placeholder='Phường/Xã'
                filterOption
                optionFilterProp='label'
              />
            </Form.Item>
            <Form.Item>
              <Button size='large' className='btn-submit btn-register' type='primary' htmlType='submit'>
                Đăng Ký
                <span className='btn-register-icon'>
                  <ChevronRight fill='white' />
                </span>
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default connect(
  state => ({
    user: state.user.user,
  }),
  dispatch => ({
    registerConsumer: values => dispatch(register(values)),
  })
)(Register);
