import React from 'react';
import Layout from '~components/layout';
import Seo from '~components/seo';
import Register from '~containers/authenticate/register';

const RegisterPage = ({location}) => {
  return (
    <Layout>
      <Seo title='Register' />
      <Register isGetInfoFromStorage={location?.state?.isGetInfoFromStorage} />
    </Layout>
  );
};

export default RegisterPage;
